import { notificationStore } from './notification-store'

export class NotificationMessages
{

    getOutageData = () =>
    {
        return fetch(process.env.VUE_APP_NOTIFICATION_DATA_URL, { credentials: 'include' }
        ).then(response => response.json())
            .then(data =>
            {
                notificationStore.setNotificationMessage(data);
            });
    }
}
