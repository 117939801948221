
import { Component, Vue, Prop } from 'vue-property-decorator';
import { app } from "../outages/app";
import 'vue-class-component/hooks';
import OutageDetail from './OutageDetail.vue';
import { Outage, outageStore } from '../outages/outage-store';

@Component({
    components: {
        OutageDetail
    },
})
export default class Map extends Vue
{
    selectedOutage = {};
    showOutageDetail = false;

    mounted(): void
    {
        app.onMarkerClicked = this.openOutageDetail;
        app.initMap();
    }

    openOutageDetail(outage: Outage)
    {
        if (outage)
        {
            this.selectedOutage = outage;
            this.showOutageDetail = true;
        }
        else
        {
            this.selectedOutage = {};
            this.showOutageDetail = false;
        }
    }
}
